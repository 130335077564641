import React from 'react';
import AppHeader from "../components/AppHeader";
import {Container, Form, Grid, Header, Icon, Label, Segment} from "semantic-ui-react";
import {ApiService as Api} from "../services/api";

export default class Account extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            token: null,
            valid: false,
            error: null,
            loading: false,
            saved: false
        }

        Api.request('account/token/bridge').then((token) => {
            this.setState({
                token: token
            });
        }, (error) => {
            // Service not setup
        });
    }

    handleChange = (e, target) => {
        this.setState({
            valid: false,
            error: null
        });

        let value = target.checked ? target.checked : target.value;
        return this.setState({ [target.name]: value })
    };

    // testConnection = () => {
    //     Api.request('https://broker.bronos.net/v1/' + this.state.token + '/zones').then((data) => {
    //         if (data.length) {
    //             this.setState({
    //                 valid: true
    //             });
    //         } else {
    //             this.setState({
    //                 error: "No devices found"
    //             });
    //         }
    //     }, () => {
    //         this.setState({
    //             error: "Invalid token"
    //         });
    //     });
    // }

    save = () => {
        this.setState({
            loading: true,
            error: null
        });

        Api.request('account/token/bridge', { token: this.state.token}, "POST").then((data) => {
            this.setState({
                saved: true
            });
        }, (error) => {
            console.error(error);
            this.setState({
                error: error
            });
        }).finally(() => {
            this.setState({
                loading: false
            });
        });
    }

    render() {
        return (
            <Container className="page page--account">
                <AppHeader/>

                <Grid centered stackable>
                    <Grid.Column width={10}>
                        <Segment>
                            <Header as='h3'>
                                Sonos support
                                <Label color='red'>Experimental</Label>
                            </Header>
                            <p>Neither the Spotify or Sonos APIs support controlling playback of Spotify tracks on a Sonos speaker. There is a workaround but it's not for the faint hearted 😭.</p>
                            <Header as='h4'>Secret</Header>
                            <Form>
                                <Form.Group>
                                    <Form.Input
                                        width={8}
                                        placeholder='Access token'
                                        name='token'
                                        value={this.state.token}
                                        onChange={this.handleChange}
                                        error={this.state.error ? {
                                            content: this.state.error,
                                            pointing: 'above',
                                        } : null}
                                    />
                                    <Form.Button
                                        fluid
                                        width={4}
                                        content={this.state.valid ? <Icon name='checkmark'/> : 'Test'}
                                        color={this.state.valid ? 'green' : 'orange'}
                                        onClick={this.testConnection}
                                        disabled={true || this.state.valid}
                                    />
                                    <Form.Button
                                        fluid
                                        width={4}
                                        content={this.state.saved ? 'Saved' : 'Save'}
                                        color={this.state.saved ? 'green' : 'orange'}
                                        // disabled={!this.state.valid}
                                        loading={this.state.loading}
                                        onClick={this.save}
                                    />
                                </Form.Group>
                            </Form>
                            <Header as='h3'>Installation</Header>
                            <p>To enable Sonos support you need to run the <a href="https://gitlab.com/0x6C77/grooveshare-bridge" target="_blank">grooveshare-bridge</a> on the same network as your Sonos speaker. This bridge needs to be running whenever you want to use Grooveshare.</p>
                            <p>Two install options below. Docker is the preferred method however feel free to run it manually to try it out or debug issues. You can also manually run it on a homeserver/Raspberry Pi.</p>

                            <Header as='h4'>Docker</Header>
                            <p>
                                <code>
                                    <span className="code-highlight">docker build</span> <span className="code-property">--tag</span> grooveshare-bridge https://gitlab.com/0x6C77/grooveshare-bridge.git<br/>
                                    <span className="code-highlight">docker run</span> <span className="code-property">-d</span> <span className="code-property">--net</span>=host <span className="code-property">--restart</span> unless-stopped <span className="code-property">--name</span> grooveshare-bridge grooveshare-bridge<br/>
                                    <br/>
                                    <span className="code-highlight">docker logs</span> grooveshare-bridge<br/>
                                    <span class="code-comment"># You should see your token and a message saying the bridge connection was successful</span>
                                </code>
                            </p>
                            <Header as='h4'>Manually</Header>
                            <p>
                                <code>
                                    <span className="code-highlight">git clone</span> https://gitlab.com/0x6C77/grooveshare-bridge.git<br/>
                                    <span className="code-highlight">cd</span> grooveshare-bridge<br/>
                                    <span className="code-highlight">npm install</span> <span className="code-property">--production</span><br/>
                                    <span className="code-highlight">npm start</span><br/>
                                    <span className="code-comment"># You should see your token and a message saying the bridge connection was successful</span>
                                </code>
                            </p>
                            <p>You will need to keep the terminal open and re-run it each time you restart your computer.</p>
                        </Segment>
                    </Grid.Column>
                </Grid>
            </Container>
        )
    }
}

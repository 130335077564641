import {ApiService as Api} from './api';

window.Pusher.logToConsole = window.location.hostname === "localhost";

let Pusher = {
    pusher: null,
    get: function(token) {
        if (!this.pusher) {
            this.pusher = new window.Pusher('82a6310b306e87854369', {
                enabledTransports: ['ws'],
                wsHost: window.location.hostname,
                wsPort: 6001,
                forceTLS: window.location.hostname !== "localhost",
                authEndpoint: Api.getEndpoint() + '/broadcasting/auth',
                auth: {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            });
        }

        return this.pusher;
    }
};

export default Pusher;

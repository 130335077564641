import * as React from "react";
import { Search as SearchUI } from "semantic-ui-react";
import SearchCtrl from "../controllers/search";
import _ from "lodash";

class Search extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            results: [],
            query: '',
        };

        this.search = _.debounce(this.search, 500);
    }

    search = (query) => {
        this.setState({
            loading: true
        });

        SearchCtrl.query(query).then(results => {
            this.setState({
                loading: false,
                results: results
            });
        });
    };

    handleResultSelect = (e, { result }) => {
        this.setState({
            loading: true
        });

        this.props.onSelect(result).then(() => {
            this.setState({
                loading: false,
                query: ''
            });
        });
    }

    handleChange = (e, { value }) => {
        this.setState({
            loading: value.length >= 3,
            query: value
        });

        if (value.length >= 3) {
            this.search(value);
        }
    }

    resultRenderer = (item) => {
        return [
            item.album && item.album.image && (
                <div key='image' className='image'>
                    <img src={item.album.image} alt={item.album.title + ' cover art'} />
                </div>
            ),
            <div key='content' className='content disabled'>
                <div className='title'>{item.title}</div>
                {item.artist && <div className='artist'>{item.artist.name}</div>}
                {item.album && <div className='album'>{item.album.title}</div>}
            </div>,
        ]
    }

    render() {
        return (
            <SearchUI
                className="channel-add-search"
                loading={this.state.loading}
                onResultSelect={this.handleResultSelect}
                onSearchChange={this.handleChange}
                resultRenderer={this.resultRenderer}
                results={this.state.results}
                value={this.state.query}
                aligned='right'
                placeholder='Add track'
                size='mini'
            />
        )
    }
}

export default Search;

import * as React from "react";

import Auth from "../../services/auth";

export default class LoginCallback extends React.Component {
    constructor(props) {
        super(props);

        // Check if we have a login
        let message = "Hang on",
            driver = props.match.params.driver,
            searchParams = Object.fromEntries(new URLSearchParams(props.location.search));

        Auth.handleAuthCallback(driver, searchParams).then(state => {
            // Redirect to home
            this.props.history.push(state.redirect);
        }, error => {
            this.setState({
                message: error
            });
        })

        this.state = {
            message: message
        };
    }

    render() {
        return this.state.message;
    }
}

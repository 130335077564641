import {Link} from "react-router-dom";
import {Card, Icon, Image, Label} from "semantic-ui-react";
import {ApiService} from "../services/api";
import React from "react";

import './ChannelCard.scss';

let ChannelCard = (props) => (
    <Card fluid as={Link} to={"channel/" + props.channel.slug} className={['card--channel', props.current ? 'card--channel-current horizontal' : '']}>
        {
            props.current ?
                <Label attached={'top'} color='red' >Currently playing</Label>
            : null
        }
        <Image src={ ApiService.getEndpoint() + '/channel/' + props.channel.id + '/cover' } wrapped ui={false}/>
        <Card.Content>
            <Card.Header>{ props.channel.title }</Card.Header>
            {
                props.channel.description ?
                    <Card.Meta>
                        { props.channel.description }
                    </Card.Meta>
                    : null
            }

            {
                props.channel.genres && props.channel.genres.length ?
                    <Card.Meta className={'channel--genres'}>
                        { props.channel.genres.map((genre) => ( <Label size={'tiny'}>{ genre }</Label> ))}
                    </Card.Meta>
                    : null
            }
        </Card.Content>
        <Card.Content className={'extra extra--meta'}>
            <Label><Icon name='music' /> { props.channel.songs }</Label>
            {
                props.channel.listening > 0 ?
                    <Label><Icon name='sound' /> { props.channel.listening }</Label>
                : null
            }
            {
                props.channel.private ?
                    <Label color='teal' style={{float: 'right'}}><Icon name='lock' /> Private</Label>
                : null
            }
        </Card.Content>
    </Card>
);

export default ChannelCard;

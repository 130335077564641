import * as React from "react";
import {withRouter} from "react-router";
import {Button, Checkbox, Form, Icon, Modal} from "semantic-ui-react";
import ChannelsCtrl from "../controllers/channels";
import SpotifyCtrl from "../controllers/spotify";

class CreateChannel extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            creatingChannel: false,
            newChannelTitle: '',
            newChannelDescription: '',
            newChannelPlaylist: null,
            newChannelPrivate: false,
            playlists: []
        };

        SpotifyCtrl.getPlaylists().then((playlists) => {
            if (!playlists) return;

            this.setState({
                playlists: playlists.map(item => {
                    return {
                        key: item.id,
                        image: item.image,
                        text: item.title,
                        value: item
                    }
                })
            });
        });
    }

    handleChange = (e, target) => {
        let value = target.checked ? target.checked : target.value;
        return this.setState({ [target.name]: value })
    };

    selectPlaylist = (e, target) => {
        return this.setState({
            newChannelPlaylist: target.value.id,
            newChannelTitle: target.value.title,
            newChannelDescription: target.value.description,
        });
    };

    createChannel = () => {
        this.setState({
            creatingChannel: true
        });

        ChannelsCtrl.create(this.state.newChannelTitle, this.state.newChannelDescription, !this.state.newChannelPrivate, this.state.newChannelPlaylist).then((data) => {
            this.props.history.push("/channel/" + data.slug);
        }, () => {
            this.setState({
                creatingChannel: false
            });
        });
    }

    render() {
        return (
            <Modal
                open={this.props.open}
                centered={false}
                closeIcon={true}
                onClose={this.props.onClose}
            >
                <Modal.Header>Create new channel</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field width={6}>
                            <Form.Input fluid
                                        label='Channel title'
                                        placeholder='Channel title'
                                        name='newChannelTitle'
                                        value={this.state.newChannelTitle}
                                        onChange={this.handleChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input fluid
                                        label='Channel description'
                                        placeholder='Channel description'
                                        name='newChannelDescription'
                                        value={this.state.newChannelDescription}
                                        onChange={this.handleChange}
                            />
                        </Form.Field>
                        {
                            this.state.playlists && this.state.playlists.length ?
                                <Form.Field>
                                    <Form.Dropdown
                                        clearable
                                        options={this.state.playlists}
                                        selection
                                        search
                                        deburr
                                        fluid
                                        name='newChannelPlaylist'
                                        onChange={this.selectPlaylist}
                                        label='Import Spotify playlist'
                                        placeholder='Playlist'
                                    />
                                </Form.Field>
                            : null
                        }
                        <Form.Field>
                            <Form.Input fluid
                                        control={Checkbox}
                                        toggle
                                        label='Private channel'
                                        name='newChannelPrivate'
                                        checked={this.state.newChannelPrivate}
                                        onChange={this.handleChange}
                            />
                            <p>
                                {
                                    this.state.newChannelPrivate ?
                                        'Only users with the link will have access to this channel'
                                    :
                                        'Channel will be listed in public directory'
                                }
                            </p>
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button loading={this.state.creatingChannel} icon labelPosition='right' color='green' onClick={this.createChannel}>
                        <Icon name='arrow right' />
                        Create channel
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withRouter(CreateChannel);

import * as React from "react";
import {Header, Grid, Button} from "semantic-ui-react";

import Auth from "../../services/auth";

import './Login.scss';

export default class Login extends React.Component {
    constructor(props) {
        super(props);

        if (props.history.location.state && props.history.location.state.referer) {
            Auth.setReferer(props.history.location.state.referer);
        }
    }

    render() {
        let loginURL = Auth.generateAuthURI('spotify');

        return (
            <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle' className='page--login'>
                <Grid.Column style={{ maxWidth: 450 }}>
                    <Header as='h1'>Grooveshare</Header>
                    <p>Connect with users all over the world by joining channels and sharing in the experience of music.</p>

                    <p>
                        <Button href={loginURL}>Connect with Spotify</Button>
                    </p>

                    <p className="subtle">Spotify Premium account is required. This site does not allow you to listen to songs directly and must be linked to an ongoing Spotify session.</p>
                </Grid.Column>
            </Grid>
        )
    }
}

import axios from 'axios'

export class ApiService {
    static setToken(token) {
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`};
    }

    static getEndpoint() {
        return document.location.hostname === '127.0.0.1' ? "http://127.0.0.1:8000/api" : "https://grooveshare.co.uk/api";
    }

    static request(route, data = null, method = "GET", headers = null) {
        let baseURL = this.getEndpoint();

        let url,
            remote = route.indexOf("http") === 0;
        if (remote) {
            url = route;
        } else {
            url = `${baseURL}/${route}`;
        }

        if (data && method === "POST") {
            var formData = new FormData();

            for (var key in data) {
                formData.append(key, data[key]);
            }

            // Clear data
            data = {};
        } else if (!data) {
            data = {};
        }

        let query = Object.entries(data).map(([key, val]) => `${key}=${val}`).join('&');
        if (query) {
            url += "?" + query;
        }

        if (!headers) {
            headers = {
                "Accept": "application/json"
            };
        }

        if (!remote && this.key){
            headers["Authorization"] = "Bearer "+ this.key;
            //headers["Content-Type"] = "application/x-www-form-urlencoded";
        }

        return new Promise((resolve, reject) => {
            axios({
                url: url,
                method: method,
                data: formData ? formData : null,
                headers: headers,
                timeout: 30000
            }).then((response) => {
                resolve(response.data.data !== undefined ? response.data.data : response.data);
            }, (error) => {
                if (error.response && error.response.status === 401) {
                    return reject("Not logged in");
                } else if (error.response && error.response.data && error.response.data.message) {
                    return reject(error.response.data.message);
                } else {
                    return reject("Server error");
                }
            })
        })
    }
}

import * as React from "react";

import Auth from "../../services/auth";

export default class Login extends React.Component {
    constructor(props) {
        super(props);

        Auth.logout();
    }

    render() {
        return null;
    }
}
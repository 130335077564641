import {ApiService as Api} from '../services/api.js';
import Channel from '../models/channel.js';

var Channels = {};

Channels.getList = function() {
    return Api.request( 'channels').then((data) => {
        return data;
    }, () => {
        console.error("Could not load channel list");
    });
};

Channels.get = function(id) {
    var channel = new Channel(id);
    return channel.loadData().then(() => {
        return channel;
    });
};

Channels.create = function(title, description, access, playlist) {
    let data = { title: title, description: description, private: !access };
    if (playlist) {
        data.playlist_id = playlist;
    }

    return Api.request('channels/create', data, 'POST').then((data) => {
        return data;
    }, () => {
        console.error("Could not load channel list");
    });
};

export default Channels;

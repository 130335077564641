import {ApiService as Api} from '../services/api.js';

var Spotify = {};

Spotify.getPlaylists = function() {
    return Api.request( 'spotify/playlists').then((data) => {
        return data;
    }, () => {
        console.error("Could not load playlists");
    });
};

export default Spotify;

import {Card} from "semantic-ui-react";
import React from "react";
import ChannelCard from "./ChannelCard";
import Slider from "react-slick";

import './ChannelCarousel.scss';

let ChannelCarousel = (props) => {
    if (!props.channels || !props.channels.length) {
        return "Loading...";
    }

    let channelList = props.channels.map((channel, index) => {
        return <ChannelCard channel={channel} key={channel.id}/>;
    });

    let settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <Slider {...settings}>
            { channelList }
        </Slider>
    );
}

export default ChannelCarousel;

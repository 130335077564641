import React, { Component } from 'react';
import { Progress } from 'semantic-ui-react';

export default class NowPlaying extends Component {

    constructor(props) {
        super(props);

        this.state = {
            playing: props.playing
        };

        this.startTick();
    }

    startTick = () => {
        this.tick = setInterval(() => {
            let now = Math.floor(new Date().getTime()),
                timestamp = now - new Date(this.state.playing.started).getTime(),
                duration = this.state.playing.song.duration,
                progress = timestamp/duration * 100;

            if (progress > 100) {
                progress = 100;
            }

            this.setState({
                progress: progress
            });
        }, 100);
    }

    componentWillReceiveProps = (props) => {
        clearInterval(this.tick);

        this.setState({
            playing: props.playing
        });

        this.startTick();
    }

    componentWillUnmount = () => {
        clearInterval(this.tick);
    }

    render() {
        if (!this.state.playing) return null;

        return (
            <div className="playing">
                <div className="vinyl">
                    <div className="grooves"></div>
                    <div className="shine"></div>
                    <div className="macaron">
                        <img className="cover" src={ this.state.playing.song.album.image } alt={ this.state.playing.song.album.title + ' album artwork' } />
                    </div>
                </div>

                <Progress percent={this.state.progress} size="tiny" />

                <h1 className="playing-title">
                    { this.state.playing.song.title }
                </h1>
                <h3 className="playing-artist">
                    { this.state.playing.song.artist.name }
                </h3>
                <h5 className="playing-album">
                    { this.state.playing.song.album.title }
                </h5>
            </div>
        );
    }

}

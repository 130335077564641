import {ApiService as Api} from '../services/api.js';

var Search = {};

Search.query = function(term) {
    return Api.request('search', { q: term }, 'GET').then((data) => {
        return data;
    }, () => {
        console.error("Could not load search results");
    });
};

export default Search;
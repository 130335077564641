import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';

import 'fomantic-ui-css/semantic.css';
import {Redirect, Route, Switch} from "react-router";
import {BrowserRouter} from "react-router-dom";

import Auth from "./services/auth";
import NotFound from "./screens/NotFound";
import Login from "./screens/auth/Login";
import LoginCallback from "./screens/auth/LoginCallback";
import Logout from "./screens/auth/Logout";
import Home from "./screens/Home";
import Channel from "./screens/Channel";
import {ToastProvider} from "react-toast-notifications";
import Account from "./screens/Account";


function PrivateRoute ({component: Component, ...rest}) {
    return (
        <ToastProvider
            placement='bottom-right'
            autoDismiss={true}
        >
            <Route
                {...rest}
                render={(props) => Auth.isLoggedIn() === true
                    ? <Component {...props} />
                    : <Redirect to={{pathname: '/login', state: {redirect: props.location}}} />}
            />
        </ToastProvider>
    )
}


ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Switch>
                <PrivateRoute exact path="/" component={Home} />
                <PrivateRoute exact path="/channel/:id" component={Channel} />


                <PrivateRoute exact path="/account" component={Account} />

                {/* AUTH */}
                <Route exact path="/login" component={Login} />
                <Route exact path="/login/:driver/callback" component={LoginCallback} />
                <PrivateRoute exact path="/logout" component={Logout} />

                <Route component={NotFound} />
            </Switch>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);



document.addEventListener('fullscreenchange', exitHandler, false);
document.addEventListener('mozfullscreenchange', exitHandler, false);
document.addEventListener('MSFullscreenChange', exitHandler, false);
document.addEventListener('webkitfullscreenchange', exitHandler, false);

function exitHandler() {
    let fullscreen = (document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement !== null);
    document.getElementsByTagName( 'html' )[0].classList.toggle('is-fullscreen', fullscreen);

}

import React from 'react';
import './Channels.scss';
import ChannelsCtrl from '../controllers/channels.js';
import {Container, Button, Header, Divider} from 'semantic-ui-react'

import CreateChannel from "../components/CreateChannel";
import Session from "../services/session";
import ChannelCarousel from "../components/ChannelCarousel";
import ChannelCard from "../components/ChannelCard";

export default class Channels extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            channels: [],
            createModalOpen: false,
        };

        let session = Session.store.getRawState();
        if (session.channel) {
            this.state.currentChannel = session.channel
        }

        ChannelsCtrl.getList().then((channels) => {
            this.setState({
                channels: channels
            });
        });
    }

    componentDidMount() {
        this.sessionUnsub = Session.store.subscribe(
            s => s.channel,
            channel => {
                this.setState({
                    currentChannel: channel
                });
            }
        );
    }

    componentWillUnmount() {
        this.sessionUnsub();
    }

    createChannelOpen = () => {
        this.setState({
            createModalOpen: true
        });
    }

    createChannelClose = () => {
        if (this.state.creatingChannel) return;

        this.setState({
            createModalOpen: false
        });
    }

    render() {
        if (!this.state.channels) return "Loading...";

        return (
            <Container className="page page--channels">
                <Header as='h1' icon textAlign='center' content="Channels" subheader="Pick a channel to start listening"></Header>
                <Button icon='add' content='Create channel' labelPosition='left' onClick={this.createChannelOpen} />

                <Divider hidden/>


                {
                    this.state.currentChannel ?
                        <React.Fragment>
                            <ChannelCard channel={this.state.currentChannel} current/>
                        </React.Fragment>
                    : null
                }

                {
                    this.state.channels.top && this.state.channels.top.length ?
                        <React.Fragment>
                            <Header as="h2">Top channels</Header>
                            <ChannelCarousel channels={this.state.channels.top} />
                        </React.Fragment>
                    : null
                }
                {
                    this.state.channels.personal && this.state.channels.personal.length ?
                        <React.Fragment>
                            <Header as="h2">Your channels</Header>
                            <ChannelCarousel channels={this.state.channels.personal} />
                        </React.Fragment>
                    : null
                }

                <CreateChannel
                    open={this.state.createModalOpen}
                    onClose={this.createChannelClose}
                />
            </Container>
        );
    }
}

import {Dropdown, Icon, Label, Menu} from "semantic-ui-react";
import {Link} from "react-router-dom";
import Search from "./Search";
import Auth from "../services/auth";
import React from "react";

let AppHeader = (props) => (
    <Menu>
        <Menu.Item
            as={Link}
            to='/'
            icon='chevron left'
            name='Channels'
        />

        <Menu.Menu position='right'>
            {
                props.onSearchSelect ?
                    <Menu.Item className="mobile hidden">
                        <Search
                            onSelect={props.onSearchSelect}
                        />
                    </Menu.Item>
                : null
            }
            {
                props.onShowTracklist ?
                    <Menu.Item
                        onClick={props.onShowTracklist}
                    >
                        <Icon name='list'/>
                        Tracklist
                        {
                            props.queue && props.queue.length ?
                                <Label color='red' ribbon floating size='tiny' style={{transform: 'none', left: 'auto', right: 2}}>{props.queue.length}</Label>
                                : null
                        }
                    </Menu.Item>
                    : null
            }

            <Dropdown item text={Auth.getProfile().name}>
                <Dropdown.Menu>
                    <Dropdown.Item as={Link} to='/account'>Account</Dropdown.Item>
                    <Dropdown.Item onClick={() => {
                        if (!document.fullscreenElement) {
                            document.documentElement.requestFullscreen();
                        } else {
                            if (document.exitFullscreen) {
                                document.exitFullscreen();
                            }
                        }
                    }}>Enter kiosk mode</Dropdown.Item>
                    <Dropdown.Item as={Link} to='/logout'>Logout</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </Menu.Menu>
    </Menu>
);

export default AppHeader;

import { Store } from "pullstate";
import Pusher from "./pusher";
import {ApiService as Api} from "./api";

class Session {
    constructor() {
        this.store = new Store({
            channel: null,
        });
    }

    setup(userID, token) {
        this.private = Pusher.get(token).subscribe('private-user-' + userID);

        this.private.bind('listening-stop', (data) => {
            console.log("Stopped listening");
            this.store.update(s => {
                s.channel = null;
            });
        });

        this.private.bind('listening-start', (data) => {
            console.log("Started listening to", data);
            this.store.update(s => {
                s.channel = data.channel;
            });
        });

        // Get current session
        Api.request('status').then(data => {
            console.log(data);

            if (data.channel) {
                this.store.update(s => {
                    s.channel = data.channel;
                });
            }
        });
    }
}


export default new Session();

import {ApiService as Api} from '../services/api.js';

var User = {};

User.setup = function() {
    return Api.request('GET', 'user').then((data) => {
        User.data = data.user;

        console.log(User.data);
    });
};

User.getDevices = function() {
    return Api.request('devices').then((devices) => {
        return devices;
    });
};

export default User;

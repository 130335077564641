import {ApiService as Api} from "./api";
import Session from "./session";
import _ from 'lodash';

class Auth {
    _localStorageKey = "grooveshare:auth";
    _isLoggedIn = false;
    _profile = null;
    _redirect = "/";

    constructor() {
        let auth = localStorage.getItem(this._localStorageKey);
        if (auth) {
            auth = JSON.parse(auth);

            if (auth.profile && !auth.profile.id) {
                localStorage.clear();
                window.location.reload();
            }

            this.setData(auth);
        } else {
            console.log("No login data");
        }

        // Check if redirect is set for after callback
        let referer = localStorage.getItem(this._localStorageKey + ":referer");
        if (referer) {
            this._redirect = referer;
            localStorage.removeItem(this._localStorageKey + ":referer");
        }
    }

    setData(data, store = false) {
        console.log(data);

        this._isLoggedIn = true;
        this._profile = data.profile;
        this._services = data.services;
        this._token = data.api_token;

        Api.setToken(data.api_token);
        Session.setup(data.profile.id, data.api_token);

        if (store) {
            localStorage.setItem(this._localStorageKey, JSON.stringify(data));
        }
    }

    isLoggedIn() {
        return !!this._isLoggedIn;
    }

    getProfile() {
        return this._profile;
    }

    getToken() {
        return this._token;
    }

    getService(service) {
        return _.find(this._services, { 'provider': service });
    }

    generateAuthURI(driver) {
        return Api.getEndpoint().slice(0, -4) + "/auth/" + driver.toLowerCase() + "/redirect";
    }

    handleAuthCallback(driver, params) {
        return new Promise((resolve, reject) => {
            if (!Object.keys(params).length) {
                return reject("Invalid request");
            } else {
                // Send request to API
                Api.request("auth/" + driver + "/token", params).then(data => {
                    console.log(data);

                    // Store
                    this.setData(data, true);

                    return resolve({
                        redirect: this._redirect
                    });
                }, (error) => {
                    return reject(error);
                });
            }
        })
    }

    logout() {
        localStorage.clear();
        window.location = "/";
    }

    setReferer(referer = null) {
        if (referer) {
            localStorage.setItem(this._localStorageKey + ":referer", referer);
        }
    }
}

export default new Auth();

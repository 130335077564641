import React, { Component } from 'react';
import {List, Image, Button, Search, Segment, Header} from 'semantic-ui-react';

import _ from 'lodash';
import {filter} from 'fuzzaldrin';

export default class TrackList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tracks: this.props.tracks ? this.sortTracks(this.props.tracks) : this.props.queue,
            searchResults: null,
            queue: this.props.queue,
            currentlyPlaying: this.props.currentlyPlaying
        }

        this.search = this.search.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({
            tracks: props.tracks ? this.sortTracks(props.tracks) : props.queue,
            queue: props.queue,
            currentlyPlaying: props.currentlyPlaying
        });
    }

    sortTracks(tracks) {
        return _.sortBy(tracks, ['artist.name', 'title']);
    }

    search(e, input) {
        console.log(input.value);

        let results = null
        if (input.value.length) {
            results = filter(this.state.tracks, input.value, {key: 'title'});
            console.log(results);
        }

        this.setState({
            searchResults: results
        });
    }

    render() {
        let items = this.state.searchResults ? this.state.searchResults : this.state.tracks;


        let list;
        if (!items.length && !this.props.search) {
            list = (
                <Segment placeholder basic style={{height: '100%'}} textAlign="center">
                    <Header>
                        No songs in list
                    </Header>
                </Segment>
            )
        } else {
            list = (
                <List relaxed divided verticalAlign='middle'>
                    {items.map((track, index) => {
                        let queued = _.find(this.state.queue, ['spotify_id', track.spotify_id]),
                            allowQueueing = this.props.onSelect && this.state.currentlyPlaying.song.spotify_id !== track.spotify_id;

                        return (
                            <List.Item key={ track.id }>
                                <Image avatar src={ track.album.image } />
                                <List.Content>
                                    <List.Header>{ track.title }</List.Header>
                                    <List.Description as="a" onClick={() => this.handleSearch('artist:' + track.artist.name)}>{ track.artist.name }</List.Description>
                                </List.Content>
                                {
                                    allowQueueing ?
                                        <Button
                                            basic={!queued}
                                            disabled={queued}
                                            icon={ queued ? 'checkmark' : 'plus' }
                                            color="green"
                                            size="mini"
                                            floated="right"
                                            onClick={() => this.props.onSelect(track.spotify_id)}
                                        />
                                        : null
                                }
                            </List.Item>
                        );
                    })}
                </List>
            )
        }

        return (
            <React.Fragment>
                {
                    this.props.search ?
                       <Search open={false} onSearchChange={this.search} fluid placeholder="Search channel songs"/>
                    : null
                }

                { list }
            </React.Fragment>
        );
    }

}

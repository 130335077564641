import {ApiService as Api} from '../services/api.js';

export default class Channel {

    constructor(id) {
        this.id = id;

        console.log('New channel', id);
    }

    loadData() {
        return Api.request('channel/' + this.id).then((data) => {
            this._data = data;

            return this._data;
        }, () => {
            console.error("Could not find channel");
        });
    }

    getData() {
        return this._data;
    }

    queue(spotifyId) {
        console.log("Queuing ", spotifyId);
        return Api.request( 'channel/' + this.id + '/queue', { spotifyId: spotifyId }, 'POST').then((data) => {
            return data;
        }, () => {
            console.error("Could not load channel list");
        });
    }

    getTrackList() {
        return Api.request('channel/' + this.id + '/tracklist').then((data) => {
            return data;
        }, () => {
            console.error("Could not load channel list");
        });
    }

    getQueue() {
        return Api.request('channel/' + this.id + '/queue').then((data) => {
            return data;
        }, () => {
            console.error("Could not load channel list");
        });
    }

    getCurrentlyPlaying() {
        return Api.request('channel/' + this.id + '/playing').then((data) => {
            this.offset = new Date().getTime() - data.time;
            this.playing = data;

            return data;
        }, () => {
            console.error("Could not load channel list");
        });
    }

    startListening(deviceId) {
        return Api.request('channel/' + this.id + '/listen', {deviceId: deviceId}, 'POST').then((data) => {
            return data;
        }, () => {
            console.error("Could not start listening");
        });
    }
}

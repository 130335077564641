import React from 'react';
import './Channel.scss';
import ChannelsCtrl from '../controllers/channels.js';
import UserCtrl from '../models/user.js';
import TrackList from '../components/TrackList.js';
import NowPlaying from '../components/NowPlaying.js';
import {
    Container,
    Header,
    Dropdown,
    Button,
    Modal,
    Label,
    Tab,
    Confirm,
    Form,
    Segment
} from 'semantic-ui-react';
import { Link } from "react-router-dom";

import _ from 'lodash';

import Pusher from "../services/pusher";
import {ApiService as Api} from "../services/api";
import Auth from "../services/auth";
import Session from "../services/session";

import { useToasts } from 'react-toast-notifications'
import AppHeader from "../components/AppHeader";

function withToast(Component) {
    return function WrappedComponent(props) {
        const toastFuncs = useToasts()
        return <Component {...props} {...toastFuncs} />;
    }
}


class Channel extends React.Component {
    id;

    constructor(props) {
        super(props);

        this.state = {
            tracklist: [],
            devices: [],
            showDeleteConfirmation: false,
            deleteChannelInput: '',
            deleteChannelError: false
        };

        this.channelID = props.match.params.id;
        ChannelsCtrl.get(this.channelID).then((channel) => {
            this.channel = channel;

            let channelData = channel.getData(),
                session = Session.store.getRawState();

            this.setState({
                channel: channelData,
                playing: channelData.nowPlaying,
                queue: [],
                listening: session.channel && parseInt(session.channel.id) === parseInt(channelData.id),
                currentChannel: session.channel ? session.channel : null
            }, () => {
                this.subscribe();
                this.updateTrackList();
                this.updateQueue();
            });
        });
    }

    subscribe() {
        console.log("Subscribing", this.state.channel.id);

        this.sessionUnsub = Session.store.subscribe(
            s => s.channel,
            channel => {
                this.setState({
                    listening: channel && parseInt(channel.id) === parseInt(this.state.channel.id),
                    currentChannel: channel ? channel : null
                });
            }
        );

        this.pusher = Pusher.get().subscribe('channel-' + this.state.channel.id);

        this.pusher.bind("pusher:subscription_succeeded", () => {
            // Connected to channel
            if (this.channel) {
                // Re-sync info on dropped connection
                this.updateCurrentlyPlaying();
                this.updateTrackList();
                this.updateQueue();
            }
        });

        this.pusher.bind('playing-update', (data) => {
            console.log(data);

            this.setState({
                playing: data.playing,
                queue: this.state.queue.filter(function(item) {
                    return item.spotify_id !== data.playing.song.spotify_id
                })
            });
        });

        this.pusher.bind('tracklist-update', (data) => {
            console.log(data);

            console.log(this.state.tracklist);
            let tracklist = this.state.tracklist;
            tracklist.push(data.song);

            console.log(tracklist);

            this.setState({
                tracklist: tracklist
            });
        });

        this.pusher.bind('queue-update', (data) => {
            console.log(data);

            let queue = this.state.queue;
            queue.push(data.song);

            let me = data.profile.name === Auth.getProfile().name;

            this.props.addToast(<div>
                <h5>{ me ? 'You' : data.profile.name } queued</h5>
                {data.song.title + ' by ' + data.song.artist.name}
            </div>, { appearance: me ? 'success' : 'info' });

            this.setState({
                queue: queue
            });
        });
    }

    componentWillUnmount() {
        this.sessionUnsub();
        this.pusher.unbind();
        Pusher.get().unsubscribe('channel-' + this.channelID);
    }

    onSearchSelect = (item) => {
        return this.channel.queue(item.id).then((data) => {
            if (data.added) {
                this.props.addToast(
                    <div><h5>Song added</h5>{ item.title + ' by ' + item.artist.name }</div>
                    , { appearance: 'success' });
            } else {
                this.props.addToast(
                    <div><h5>Song already added to channel</h5>{ item.title + ' by ' + item.artist.name }</div>
                    , { appearance: 'error' });
            }
        });
    }

    updateCurrentlyPlaying = () => {
        console.log(this.state.playing);

        this.channel.getCurrentlyPlaying().then((playing) => {
            this.setState({
                playing: playing
            });
            console.log(this.state.playing);
        });
    }

    updateTrackList = () => {
        this.channel.getTrackList().then((tracklist) => {
            this.setState({
                tracklist: tracklist
            });
        });
    }

    updateQueue = () => {
        this.channel.getQueue().then((queue) => {
            this.setState({
                queue: queue
            });
        });
    }

    queueTrack = (trackID) => {
        return this.channel.queue(trackID);
    }

    getUserDevices = () => {
        this.setState({
            listening: 'loading'
        });

        UserCtrl.getDevices().then((devices) => {
            let options = devices.map((device) => {
                return {
                    text: device.name,
                    icon: device.provider === 'spotify' ? 'spotify' : 'volume down',
                    value: device.id,
                    provider: device.provider
                }
            });

            options.push({
                text: 'Device not showing?',
                icon: 'question circle outline icon',
                value: 0
            });

            console.log(options);

            this.setState({
                devices: options
            });
        }, (error) => {
            this.props.addToast(
                <div><h5>Error loading devices</h5>{ error}</div>
                , { appearance: 'error' });
        }).finally(() => {
            this.setState({
                listening: false
            });
        });

    }

    startListening = (e, data) => {
        if (data.value === 0) {
            this.setState({
                showDeviceHelpDialog: true
            })
            return;
        }

        // Find device in devices
        let device = _.find(this.state.devices, { value: data.value });
        console.log(device);

        this.setState({
            listening: "loading",
            listeningDevice: device.text
        });

        let url = "channel/" + this.channelID + "/listen/start";

        Api.request(url, {
            deviceID: device.value,
            provider: device.provider
        }).then(data => {
            this.setState({
                listening: true
            });
        }, error => {
            console.error(error);
        });
    }

    stopListening = () => {
        this.setState({
            listening: 'loading'
        });

        let url = "channel/" + this.channelID + "/listen/stop";

        Api.request(url).then(data => {
            console.log(data);
            this.setState({
                listening: false
            });
        }, error => {
            console.error(error);
        });
    }

    handleChange = (e, target) => {
        if (target.name === "deleteChannelInput") {
            this.setState({
                deleteChannelError: null
            });
        }

        return this.setState({ [target.name]: target.value })
    };

    deleteChannel(input) {
        let valid = input.toLowerCase() === this.state.channel.title.toLowerCase();

        this.setState({
            deleteChannelError: valid ? null : 'Incorrect channel name'
        });
        if (!valid) return;

        let url = "channel/" + this.state.channel.id;

        Api.request(url, null, "DELETE").then(data => {
            this.props.history.push("/")
        }, error => {
            this.setState({
                deleteChannelError: error
            });
        });

    }

    render() {
        if (!this.state.channel) return null;

        return (
            <Container className="page page--channel">
                <AppHeader
                    onSearchSelect={this.onSearchSelect}
                    onShowTracklist={() => { this.setState({ showTracklist: true }) }}
                    queue={this.state.queue}
                />

                <Header as='h1' icon textAlign='center' content={this.state.channel.title} subheader={this.state.channel.description}></Header>

                <Container textAlign="center">
                    {
                        this.state.listening !== true ?
                            <Dropdown
                                loading={this.state.listening === "loading"}
                                button
                                className='icon'
                                icon='sound'
                                labeled
                                pointing
                                text={!this.state.listening ? "Start listening" : "Playing on device"}
                                options={this.state.devices}
                                noResultsMessage='Try another search.'
                                selectOnBlur={false}
                                selectOnNavigation={false}
                                onOpen={this.getUserDevices}
                                onChange={this.startListening}
                            />
                        :
                            <Button
                                color='red'
                                content='Stop listening'
                                icon='stop'
                                labelPosition='left'
                                onClick={this.stopListening}
                            />
                    }
                </Container>

                <Modal className='modal--help' open={this.state.showDeviceHelpDialog} onClose={() => { this.setState({ showDeviceHelpDialog: false })}} size="tiny">
                    <Modal.Header>Can't see your device?</Modal.Header>
                    <Modal.Content>
                        <p>Grooveshare can only play music through an active Spotify session. This can either be the Spotify app on your desktop/smartphone or a smart speaker. If your player is not showing make sure the Spotify app is open on your device.</p>
                        <p>Not all smart speakers are supported officially by Spotify e.g. Sonos. <Link to='/account'>Click here</Link> to try our work around.</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button positive onClick={() => this.setState({ showDeviceHelpDialog: false })}>
                            Close
                        </Button>
                    </Modal.Actions>
                </Modal>

                <Modal className='modal--tracklist' open={this.state.showTracklist} onClose={() => { this.setState({ showTracklist: false })}} closeIcon={true}>
                    <Modal.Header>
                        {this.state.channel.title} songs
                    </Modal.Header>
                    <Modal.Content>
                        <Tab menu={{ fluid: true, vertical: true, tabular: true }} panes={[
                            {
                                menuItem: 'Queue',
                                render: () => (
                                    <div className="scrolling content">
                                        <TrackList queue={this.state.queue} />
                                    </div>
                                )
                            }, {
                                menuItem: 'Tracklist',
                                render: () => (
                                    <div className="scrolling content">
                                        <TrackList
                                            tracks={this.state.tracklist}
                                            queue={this.state.queue}
                                            currentlyPlaying={ this.state.playing }
                                            onSelect={this.queueTrack}
                                            search
                                        />
                                    </div>
                                )
                            }, this.state.channel.owner ? {
                                menuItem: 'Settings',
                                render: () => (
                                    <div className="scrolling content">
                                        <Segment>
                                            <Header>Delete channel</Header>
                                            <p>Channel will be permanently deleted for all users</p>
                                            <Button color='red' icon='trash' labelPosition='left' content='Delete channel' onClick={() => { this.setState({'showDeleteConfirmation': true})}} />
                                            <Confirm
                                                header='Delete channel'
                                                content={
                                                    <div className='content'>
                                                        <p>This action cannot be undone. You will lose this channel's tracklist and all related resources. </p>
                                                        <p>Please type the following to confirm:<br/><Label>{ this.state.channel.title }</Label></p>
                                                        <Form>
                                                            <Form.Input
                                                                placeholder='Enter channel name'
                                                                name='deleteChannelInput'
                                                                value={this.state.deleteChannelInput}
                                                                onChange={this.handleChange}
                                                                error={this.state.deleteChannelError ? {
                                                                    content: this.state.deleteChannelError,
                                                                    pointing: 'below',
                                                                } : null}
                                                            />
                                                        </Form>
                                                    </div>
                                                }
                                                open={this.state.showDeleteConfirmation}
                                                onCancel={() => { this.setState({'showDeleteConfirmation': false})}}
                                                onConfirm={() => this.deleteChannel(this.state.deleteChannelInput)}
                                                confirmButton="Delete channel"
                                            />
                                        </Segment>
                                    </div>
                                )
                            } : null
                        ]} />
                    </Modal.Content>
                </Modal>

                {
                    this.state.playing ?
                        <NowPlaying playing={ this.state.playing }/>
                    : null
                }
            </Container>
        );
    }
}

export default withToast(Channel);
